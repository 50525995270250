<template>
  <div class="remote-titration-container">
        <RemoteTitrationPatientList class="remote-titration-container-left" :watchPatientArray.sync="watchPatientArray"></RemoteTitrationPatientList>
        <RemoteTitrationMain class="remote-titration-container-right" :watchPatientArray="watchPatientArray" @onClickWatch="onClickWatch"></RemoteTitrationMain>
  </div>
</template>

<script>
import RemoteTitrationPatientList from './remote-titration-patient-list/remote-titration-patient-list.vue';
import RemoteTitrationMain from './remote-titration-main/remote-titration-main.vue';
export default {
    components: {
        RemoteTitrationPatientList,
        RemoteTitrationMain,
    },
  data() {
    return {
        watchPatientArray: []
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    onClickWatch(item) {
        const findIndex = this.watchPatientArray.findIndex(el => el.id === item.id)
        if (findIndex !== -1) {
            this.watchPatientArray.splice(findIndex, 1)
        }
    }
  },
}
</script>
<style scoped lang='scss'>
    .remote-titration-container {
        display: flex;
        &-left {
            flex: 0.12;
        }

        &-right {
            flex: 1;
        }
    }
</style>