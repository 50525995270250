<template>
    <div class="chart-container" ref="chartBox">
        <div class="time-list" style="margin-right: 15px;" v-if="!small">
            <div class="time-list-item" v-for="item in timeList" :key="item.id"
                :class="currentTimeInterval === item.id ? 'time-list-item-select' : ''"
                @click.stop="onClickTimeItem(item.id)">{{ item.text }}</div>
        </div>
        <div class="chart" ref="chart" :style="`height:${small ? '100%' : `${boxHeight}px`};zoom:${1 / zoom
            };`"></div>
    </div>
</template>

<script>

export default {
    props: {
        timeList: Array,
        checkList: Array,
        small: Boolean,
        chartData: Object,
        currentTimeInterval: Number,
        period: Object,
        obj: Object,
    },
    data() {
        return {
            chart: null,
            boxHeight: 0,
            originHeight: 0,
            offsetTop: 60, // 距离顶部多少像素
            baseSpace: 70, // 间隔基数
            dataZoomTop: 250,
            dataZoomHeight: 40,
            titleOffset: 50,
            chartItemHeight: 0,
            eventData: [],
            chartOptions: []
        }
    },
    watch: {
        checkList: {
            handler(newVal) {
                if (newVal.length > 3) {
                    this.boxHeight = this.originHeight + (newVal.length - 3) * (this.chartItemHeight + this.baseSpace)
                } else {
                    this.boxHeight = this.originHeight
                }
                // this.$refs.chartBox.offsetHeight
                this.drawLine()
                this.refresh()
            }
        },
        small: {
            handler(newVal) {
                this.setHeight()
                this.drawLine()
                this.refresh()
            }
        }
    },
    computed: {
        zoom() {
            return this.$store.state.zoom
        },
    },
    created() {

    },
    mounted() {
        this.setHeight();
        this.chart = this.$echarts.init(this.$refs.chart);
        this.refresh()
        this.drawLine()
    },
    methods: {
        setHeight() {
            this.boxHeight = this.$refs.chartBox.offsetHeight * this.zoom;
            this.originHeight = this.boxHeight * this.zoom;
            // 设置 grid 数据
            const viewBox = this.originHeight - this.offsetTop - this.dataZoomHeight;
            // 计算间隔相差
            const space = this.baseSpace;
            const itemCount = 3
            // 计算 每个图表的高度
            this.chartItemHeight =
                Math.floor((viewBox - space * (itemCount - 1)) / itemCount);
        },
        setupChartOptions() {
            const titleArray = ["压力", "流量", "事件", "漏气", "潮气量", "呼吸率", "分钟通气量", "血氧", "心率"]
            const unitArray = ["cm H2O", "L/min", "", "L/min", "ML", "bpm", "L/min", "%", "bpm"]
            let list = []
            this.checkList.forEach(el => {
                if (el === 1) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(1),
                        type: 'line',
                    })
                } else if (el === 2) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(2),
                        type: 'line',
                    })
                } else if (el === 3) {
                    list.push({
                        title: titleArray[el - 1],
                        data: this.getChartDataList(9),
                        type: 'bar',
                        yMin: 0,
                        yMax: 900,
                        interval: 300,
                        formatter: function (value) {
                            var texts = [];
                            if (value == 0) {
                                texts.push("");
                            } else if (value <= 300) {
                                texts.push("OSA");
                            } else if (value <= 600) {
                                texts.push("Hypopnea");
                            } else if (value <= 900) {
                                texts.push("CSA");
                            } else {
                                texts.push("");
                            }
                            return texts;
                        },
                    })
                } else if (el === 4) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(3),
                        type: 'line',
                    })
                } else if (el === 5) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(4),
                        type: 'line',
                    })
                } else if (el === 6) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(5),
                        type: 'line',
                    })
                } else if (el === 7) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(6),
                        type: 'line',
                    })
                } else if (el === 8) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(7),
                        type: 'line',
                        yMin: 65,
                        yMax: 100,
                        formatter: function (value, index) {
                            return value + "%";
                        }
                    })
                } else if (el === 9) {
                    list.push({
                        title: titleArray[el - 1] + `(${unitArray[el - 1]})`,
                        data: this.getChartDataList(8),
                        type: 'line',
                        yMin: 45,
                        yMax: 120,
                    })
                }
            })
            this.chartOptions = list
        },
        drawLine() {
            this.setupChartOptions()
            if (this.small) {
                this.drawLineSmall()
            } else {
                this.drawLineDefault()
            }
        },
        drawLineDefault() {
            const xAxis = [];
            const yAxis = [];
            const grid = [];
            const series = [];
            const title = [];
            const dataZoomIndexs = []
            this.chartOptions.forEach((item, index, arr) => {
                dataZoomIndexs.push(index)
                xAxis.push({
                    type: "time",
                    gridIndex: index,
                    axisLabel: {
                        formatter: (value) => {
                            if (this.currentTimeInterval === 7 || this.currentTimeInterval === 6) {
                                return this.$echarts.format.formatTime("hh:mm:ss", new Date(value));
                            } else {
                                return this.$echarts.format.formatTime("hh:mm:ss", new Date(value));
                            }
                        },
                    },
                })
                if (index === 2) {
                    series.push({
                        name: "",
                        type: "bar",
                        symbol: "none", //点点
                        stack: "Total", //堆叠
                        barCategoryGap: "0%", //间距
                        itemStyle: {
                            borderColor: "transparent",
                            color: "transparent",
                        },
                        data: item.data.map((item) => {
                            return [item[0], this.pickPlaceHolderHeight(item[1])];
                        }),
                        xAxisIndex: index,
                        yAxisIndex: index,
                    },

                        {
                            name: "事件",
                            type: "bar",
                            symbol: "none",
                            stack: "Total",
                            data: item.data.map((item) => {
                                return [item[0], item[1] ? 200 : null];
                            }),
                            barCategoryGap: "0%",
                            itemStyle: {
                                color: (params) => {
                                    return this.pickColor(params.dataIndex);
                                },
                            },
                            xAxisIndex: index,
                            yAxisIndex: index,
                        })
                } else {
                    series.push({
                        type: item.type,
                        name: item.title,
                        symbol: "none",
                        smooth: true,
                        lineStyle: {
                            width: 1, //设置线条粗细
                            color: '#0052D9'
                        },
                        emphasis: {
                            lineStyle: {
                                width: 1, //设置选中线条粗细
                            },
                        },
                        data: item.data,
                        xAxisIndex: index,
                        yAxisIndex: index,
                    })
                }


                // 设置 y 坐标数据
                yAxis.push({
                    gridIndex: index,
                    type: 'value',
                    interval: item.interval,
                    min: item.yMin,
                    max: item.yMax,
                    axisLabel: {
                        formatter: item.formatter
                    },
                });
                let _top, _bottom, _titleTop, dataZoomHeight = 0;
                if (index !== 0) {
                    dataZoomHeight = this.dataZoomHeight
                } else {
                    this.dataZoomTop = this.chartItemHeight + this.offsetTop + 10
                }
                _top = this.offsetTop + dataZoomHeight + (this.baseSpace + this.chartItemHeight) * index;
                _bottom = this.boxHeight - (_top + this.chartItemHeight);
                _titleTop = _top - this.titleOffset;

                grid.push({
                    containLabel: true,
                    left: 35,
                    top: _top,
                    bottom: _bottom,
                    right: 25,
                });

                title.push({
                    top: _titleTop,
                    left: 20,
                    text: item.title,
                })
            })
            const tooltipFormatter = (params) => {
                // 排序
                params.sort((a, b) => a.axisIndex - b.axisIndex);
                let html = `<div style="color:#333;padding: 0 10px 10px 10px">时间：${params[0].axisValueLabel}</div>`;
                params.forEach((item, index, arr) => {
                    if (item.componentSubType === 'line') {
                        html += `
                <div style="color:#333;display: flex;flex-flow: row nowrap;align-items: center;padding:10px;border-top:1px solid rgba(0,0,0,.2)">
                  <div style="width:10px;height:10px;background:${item.color};border-radius:10px;margin-right:10px"></div>
                  <div>
                    <p style="color:#333;">类型：${item.seriesName}</p>
                    <p style="color:#333;">数值：${item.value[1]}</p>
                  </div>
                </div>
              `;
                    }
                });

                return html;
            }
            this.chart.clear()
            this.chart.hideLoading();
            this.chart.setOption({
                tooltip: {
                    trigger: "axis",
                    borderWidth: 0,
                    borderColor: "#ccc",
                    padding: 10,
                    formatter: tooltipFormatter,
                    textStyle: {
                        color: "#000",
                    },
                    axisPointer: {
                        lineStyle: {
                            type: "solid",
                        },
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                title: title,
                grid: grid,
                xAxis: xAxis,
                yAxis: yAxis,
                series: series,
                dataZoom: [
                    {
                        type: 'slider',
                        show: true,
                        realtime: true,
                        start: 0,
                        end: 100,
                        left: 50,
                        right: 50,
                        top: this.dataZoomTop,
                        xAxisIndex: dataZoomIndexs,
                        labelFormatter: (value) => {
                            return this.$echarts.format.formatTime("hh:mm", new Date(value));
                        },
                    },
                ],
            });
        },
        drawLineSmall() {
            this.chart.clear()
            this.chart.hideLoading();
            this.chart.setOption({
                tooltip: {
                    trigger: "axis",
                    borderWidth: 0,
                    borderColor: "#ccc",
                    padding: 10,
                    textStyle: {
                        color: "#000",
                    },
                    axisPointer: {
                        lineStyle: {
                            type: "solid",
                        },
                    },
                },
                axisPointer: {
                    link: [
                        {
                            xAxisIndex: "all",
                        },
                    ],
                },
                grid: {
                    containLabel: true,
                    left: 35,
                    top: this.offsetTop,
                    bottom: 60,
                    right: 25,
                },
                legend: {
                    show: true,
                    // data: ['压力', '漏气'],
                    left: '10',
                    top: '10',
                    itemHeight: 4,
                    itemWidth: 10,
                    formatter: function (name) {
                        return name.split('(')[0];
                    },
                    icon: 'rect',
                },
                xAxis: {
                    type: "time",
                    axisLabel: {
                        formatter: (value) => {
                            return this.$echarts.format.formatTime("hh:mm:ss", new Date(value));
                        },
                    },
                },
                yAxis: [
                    {
                        type: 'value'
                    },
                    {
                        type: 'value',
                        // interval: 1,
                    }
                ],

                series: [
                    {
                        type: this.chartOptions[0].type,
                        name: this.chartOptions[0].title,
                        symbol: "none",
                        smooth: true,
                        lineStyle: {
                            width: 1, //设置线条粗细
                        },
                        emphasis: {
                            lineStyle: {
                                width: 1, //设置选中线条粗细
                            },
                        },
                        data: this.chartOptions[0].data,
                    },
                    {
                        type: this.chartOptions[1].type,
                        name: this.chartOptions[1].title,
                        yAxisIndex: 1,
                        symbol: "none",
                        smooth: true,
                        lineStyle: {
                            width: 1, //设置线条粗细
                        },
                        emphasis: {
                            lineStyle: {
                                width: 1, //设置选中线条粗细
                            },
                        },
                        data: this.chartOptions[1].data,
                    }
                ],
                dataZoom: {
                    type: 'slider',
                    show: true,
                    realtime: true,
                    start: 0,
                    end: 100,
                    left: 50,
                    right: 50,
                    bottom: 20,
                    xAxisIndex: [0],
                    labelFormatter: (value) => {
                        return this.$echarts.format.formatTime("hh:mm", new Date(value));
                    },
                },
            });
        },

        drawLineByRealtime() {
            this.setupChartOptions()
            if (this.small) {
                this.drawLineSmallByRealtime()
            } else {
                this.drawLineDefaultByRealtime()
            }
        },

        drawLineSmallByRealtime() {
            this.chart.setOption({
                series: [
                    {
                        data: this.chartOptions[0].data,
                    },
                    {
                        data: this.chartOptions[1].data,
                    }
                ],
            });
        },

        drawLineDefaultByRealtime() {
            const series = [];
            this.chartOptions.forEach((item, index, arr) => {
                if (index === 2) {
                    series.push({
                        data: item.data.map((item) => {
                            return [item[0], this.pickPlaceHolderHeight(item[1])];
                        }),
                    },

                        {
                            data: item.data.map((item) => {
                                return [item[0], item[1] ? 200 : null];
                            }),
                        })
                } else {
                    series.push({
                        data: item.data,
                    })
                }

            })
            this.chart.setOption({
                series: series,
            });
        },

        changeChartDataZoom(id) {
            const dataZoom = this.chartDataZoomScope(id)
            console.log(dataZoom)
            this.chart.setOption({
                dataZoom: dataZoom,
            });
        },

        pickColor(index) {
            let barColor = "";
            switch (this.eventData[index][1]) {
                case "osa":
                    barColor = "#94CD78"; //绿
                    break;
                case "hypopnea":
                    barColor = "#F17F7F"; //红
                    break;
                case "csa":
                    barColor = "#FAC85B"; //黄
                    break;

                default:
                    break;
            }
            return barColor;
        },

        pickPlaceHolderHeight(eventType) {
            let height = "";
            switch (eventType) {
                case "osa":
                    height = "50";
                    break;
                case "hypopnea":
                    height = "350";
                    break;
                case "csa":
                    height = "650";
                    break;
                default:
                    break;
            }
            return height;
        },

        //在发生特定事件的时间戳上进行标记
        markEvent(dataArray, eventName, sourceDataArray) {
            let timeBarInterval = 0
            if (dataArray.length > 2) {
                timeBarInterval = (dataArray[2][0] - dataArray[1][0]) / 1000
            } else {
                timeBarInterval = 1
            }
            for (const item of sourceDataArray) {
                //计算事件开始时间戳在图表时间轴中的index
                const time = item[0]
                const startIndex = this._computeTimeIndex(time, dataArray, timeBarInterval)
                //计算呼吸事件事件在图表时间轴中的持续跨度
                const span = Math.round(item[1] * 1000 / timeBarInterval) //item[1]的单位为秒
                //修改数组
                for (let index = startIndex; index < startIndex + span; index++) {
                    // console.log(index)
                    if (dataArray[index]) {
                        dataArray[index][1] = eventName
                    }
                }
            }
        },

        //计算时间戳在图表时间轴中的index
        _computeTimeIndex(timeStamp, timeArray, timeBarInterval) {
            const timeGap = timeStamp - timeArray[0][0]
            const timeIndex = Math.round(timeGap / timeBarInterval)
            return timeIndex
        },
        //刷新图表
        refresh() {
            this.$nextTick(() => {
                this.chart.resize()
            })

        },
        //根据时间间隔刷新图表
        refreshChartByZoom(id) {
            this.$nextTick(() => {
                this.drawLine()
            })
        },

        //实时添加数据
        refreshChartByRealtime() {
            this.drawLineByRealtime()
        },

        onClickTimeItem(id) {
            this.$emit('onClickTimeItem', id)
        },

        chartDataZoomScope(id) {
            let zoomLock = true
            let brushSelect = false
            let endValue = this.period?.endTime ?? null
            let startValue = 0
            let zoomMs = 0
            if (id === 1) {
                zoomMs = 30 * 1000;
            } else if (id === 2) {
                zoomMs = 60 * 1000;
            } else if (id === 3) {
                zoomMs = 2 * 60 * 1000;
            } else if (id === 4) {
                zoomMs = 4 * 60 * 1000;
            } else if (id === 5) {
                zoomMs = 8 * 60 * 1000;
            } else if (id === 6) {
                zoomMs = 30 * 60 * 1000;
            } else if (id === 7) {
                zoomLock = false
                brushSelect = true
                return {
                    zoomLock,
                    brushSelect,
                    startValue,
                    endValue
                }
            } else if (id === 8) {
                zoomMs = 10 * 1000;
            } else if (id === 9) {
                zoomMs = 15 * 1000;
            } else if (id === 10) {
                zoomMs = 20 * 1000;
            }
            if (id !== 7) {
                startValue = endValue - zoomMs
            }
            return {
                zoomLock,
                brushSelect,
                startValue,
                endValue,
            }
        },

        getChartDataList(type) {
            // let currentTimestamp = 0;
            // currentTimestamp = this.period?.endTime ?? Date.now();
            // let zoomMs = 0;
            // if (this.currentTimeInterval === 1) {
            //     zoomMs = 30 * 1000;
            // } else if (this.currentTimeInterval === 2) {
            //     zoomMs = 60 * 1000;
            // } else if (this.currentTimeInterval === 3) {
            //     zoomMs = 2 * 60 * 1000;
            // } else if (this.currentTimeInterval === 4) {
            //     zoomMs = 4 * 60 * 1000;
            // } else if (this.currentTimeInterval === 5) {
            //     zoomMs = 8 * 60 * 1000;
            // } else if (this.currentTimeInterval === 6) {
            //     zoomMs = 30 * 60 * 1000;
            // } else if (this.currentTimeInterval === 7) {
            // } else if (this.currentTimeInterval === 8) {
            //     zoomMs = 10 * 1000;
            // } else if (this.currentTimeInterval === 9) {
            //     zoomMs = 15 * 1000;
            // } else if (this.currentTimeInterval === 10) {
            //     zoomMs = 20 * 1000;
            // }
            // const previousMinuteTimestamp = currentTimestamp - zoomMs;
            // console.log(previousMinuteTimestamp, this.chartData?.pressure)
            // const index = this.chartData?.pressure?.findIndex((el) => el[0] > previousMinuteTimestamp)
            // console.log(index,this.chartData?.pressure.length)
            let newList = this.chartData?.pressure ?? []
            // if (index === -1) {
            //     newList = this.chartData?.pressure
            // } else {
            //     newList = this.chartData?.pressure?.slice(index, this.chartData?.pressure.length)
            // }
            // console.log(newList)
            if (type === 9) {
                const event = newList.map(el => [el[0], null])
                this.markEvent(event, "osa", this.chartData?.event.OSA)
                this.markEvent(event, "csa", this.chartData?.event.CSA)
                this.markEvent(event, "hypopnea", this.chartData?.event.Hypopnea)
                this.eventData = event
                return event
            } else {
                return newList.map(el => [el[0], el[type]])
            }
        }
    },

}
</script>
<style scoped lang='scss'>
.chart-container {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.chart {
    width: 100%;
    height: 100%;
}

.time-list {
    display: flex;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 0px;
    z-index: 10;

    .time-list-item {
        width: 30px;
        height: 20px;
        background-color: #FFFFFF;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 20px;
        text-align: center;
        cursor: pointer;

        &:not(:last-of-type) {
            margin-right: 5px;
        }
    }

    .time-list-item-select {
        color: #2779FF;
        background-color: #ECF2FE;
        border: 1px solid #ECF2FE;
    }
}
</style>