<template>
    <div class="cell" :class="[watch ? 'cell-watch' : '']" @click="$emit('onClickCell')">
        <div v-if="obj.sn" :class="[online ? 'online' : 'offline']"></div>
        <img v-if="watch" class="watch" src="@imgs/remote-titration-cell-watch.png" alt="">
        <div class="info" :class="[watch ? 'info-watch' : '']">{{placeholderText(obj?.name)+ ' ' + placeholderText(obj?.mobile)}}</div>
    </div>
</template>

<script>

export default {
    props: {
        obj: Object,
        watch: Boolean,
        online: Boolean
    },
    data() {
        return {
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {
        placeholderText
    },
}
</script>
<style scoped lang='scss'>
.cell {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #fff;
    padding:0 6px;
    margin-top: 10px;

    cursor: pointer;
    

    .online {
        width: 10px;
        height: 10px;
        background-color: #00C103;
        border-radius: 50%;
    }

    .offline {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #C2C2C2;
    }

    .watch {
        margin-left: 8px;
        width: 15px;
        height: 10px;
    }

    .info {
        margin-left: 8px;
        height: 22px;
        width: 70%;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }
}
.cell-watch {
    background-color: #ECF2FE !important;
}
.info-watch {
    color: #2779FF !important;
}
</style>