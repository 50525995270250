<template>
    <div class="item-container">
        <div class="header">
            <div class="left">
                <span class="name">{{ obj.name }}</span>
                <span class="device-circle" v-if="obj.sn" :class="[obj?.onlineStatus == 1 ? 'online' : '']"></span>
                <span class="device">{{ deviceText }} </span>
            </div>
            <div class="right">
                <div class="time-list" style="margin-right: 15px;" v-if="small && chartData">
                    <div class="time-list-item" v-for="item in smallTimeList" :key="item.id"
                        :class="currentTimeInterval === item.id ? 'time-list-item-select' : ''"
                        @click.stop="onClickTimeItem(item.id)">{{ item.text }}</div>
                </div>
                <button class="operate m-r-22" @click.stop="onClickStart"
                    :disabled="!chartData || obj.onlineStatus != 1"><img class="icon"
                        :src="(!chartData || obj.onlineStatus != 1) ? remoteStartDisable : (isStart ? remotePause : remoteStartUnselect)" /></button>
                <ElPopover :visible-arrow="false" placement="bottom" trigger="hover" popper-class="titration-popper"
                    :append-to-body="false" :popper-options="{
                        boundariesElement: 'body',
                        gpuAcceleration: true,
                        positionFixed: true,
                        preventOverflow: true
                    }" :disabled="!chartData">
                    <button slot="reference" class="operate m-r-22" v-show="!small" @click.stop="onClickCategory"><img
                            class="icon" :src="chartData ? remoteCategory : remoteCategoryDisable" /></button>
                    <el-checkbox-group class="checkbox-group" v-model="checkList" @change="checkboxChange">
                        <el-checkbox v-for="item in categoryList" :key="item.id" :value="item.id"
                            :label="item.text"></el-checkbox>
                    </el-checkbox-group>
                </ElPopover>
                <button class="operate" @click.stop="onClickWatch"><img class="icon"
                        src="@imgs/remote-watch.png" /></button>
            </div>
        </div>

        <div class="content">
            <remoteTitrationMainItemChart ref="chartRef" v-if="obj.sn && chartData" :time-list="timeList"
                :checkList="checkIdList" :chartData="chartData" :small="small" :period="period" :obj="obj"
                :currentTimeInterval="currentTimeInterval" @onClickTimeItem="onClickTimeItem">
            </remoteTitrationMainItemChart>
            <div class="empty" v-else>
                暂无数据
            </div>
        </div>
    </div>
</template>

<script>
import { Popover, CheckboxGroup, Checkbox } from 'element-ui'
import remoteTitrationMainItemChart from './remote-titration-main-item-chart.vue'
import { DateTool } from "@js/date-tool.js";
export default {
    components: {
        ElPopover: Popover,
        ElCheckboxGroup: CheckboxGroup,
        ElCheckbox: Checkbox,
        remoteTitrationMainItemChart
    },
    props: {
        obj: Object,
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            remotePause: require("@imgs/remote-pause.png"),
            remoteStartUnselect: require("@imgs/remote-start-unselect.png"),
            remoteStartDisable: require("@imgs/remote-start-disable.png"),
            remoteCategory: require("@imgs/remote-category.png"),
            remoteCategoryDisable: require("@imgs/remote-category-unselect.png"),
            isStart: false,
            chartData: null,
            period: null,
            checkList: [
                '压力', '流量', '事件'
            ],
            checkIdList: [
                1, 2, 3
            ],
            currentTimeInterval: 7,
            timer: null,
            updateTimer: null,
            lastRealTime: 0, //上一次实时刷新时间
            realTimeInterval: 2000,//实时刷新间隔20s
            timeList: [
                {
                    id: 1,
                    text: '30s',
                },
                {
                    id: 2,
                    text: '1m',
                },
                {
                    id: 3,
                    text: '2m',
                },
                {
                    id: 4,
                    text: '4m',
                },
                {
                    id: 5,
                    text: '8m',
                },
                {
                    id: 6,
                    text: '30m',
                },
                {
                    id: 7,
                    text: 'All',
                }
            ],
            smallTimeList: [
                {
                    id: 8,
                    text: '10s',
                },
                {
                    id: 9,
                    text: '15s',
                },
                {
                    id: 10,
                    text: '20s',
                },
                {
                    id: 7,
                    text: 'All',
                }
            ],
            categoryList: [
                {
                    id: 1,
                    text: '压力',
                },
                {
                    id: 2,
                    text: '流量',
                },
                {
                    id: 3,
                    text: '事件',
                },
                {
                    id: 4,
                    text: '漏气',
                },
                {
                    id: 5,
                    text: '潮气量',
                },
                {
                    id: 6,
                    text: '呼吸率',
                },
                {
                    id: 7,
                    text: '分钟通气量',
                },
                {
                    id: 8,
                    text: '血氧',
                },
                {
                    id: 9,
                    text: '心率',
                },
            ]
        }
    },
    watch: {
        small: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.checkList = [
                        '压力', '漏气'
                    ]
                    this.checkIdList = [1, 4]
                } else {
                    this.checkList = [
                        '压力', '流量', '事件'
                    ]
                    this.checkIdList = [1, 2, 3]
                }
                this.currentTimeInterval = 7
                this.getChartData()
            }
        },
        // currentTimeInterval(newVal) {
        //     // this.refreshChartTimeInterval()
        //     this.getChartDataOnRealtime()
        // }
    },
    computed: {
        deviceText() {
            if (!this.obj.sn) {
                return '未绑定设备'
            }

            if (this.obj?.onlineStatus == 1) {
                return '设备在线'
            } else {
                let text = '设备离线'
                if (this.chartData) {
                    text += ` 最后在线时间：${this.chartData?.lastOnlineTime}`
                }
                return text
            }
        }
    },
    created() {

    },
    mounted() {
        this.getChartData()
    },
    beforeDestroy() {
        if (this.isStart) {
            this.stopRealtimeRefresh()
        }

    },
    methods: {
        checkboxChange() {
            let list = []
            for (let i = 0; i < this.checkList.length; i++) {
                for (let j = 0; j < this.categoryList.length; j++) {
                    if (this.checkList[i] === this.categoryList[j].text) {
                        list.push(this.categoryList[j].id);
                    }
                }
            }
            list.sort()
            this.checkIdList = list
        },
        onClickStart() {
            if (!this.chartData) {
                return
            }
            //点击开始 时间间隔要改为全部
            if (this.currentTimeInterval !== 7) {
                this.onClickTimeItem(7)
            }
            if (this.isStart) {
                //停止实时更新
                this.stopRealtimeRefresh()
                this.getChartData()
            } else {
                //开始实时更新
                this.startRealtimeRefresh()
            }
        },
        onClickCategory() {

        },
        onClickWatch() {
            this.$emit('onClickWatch')
        },
        startRealtimeRefresh() {
            this.lastRealTime = this.period?.endTime ?? Date.now()
            this.timer = setInterval(() => {
                this.getChartDataOnRealtime()
            }, this.realTimeInterval)
            //把数据往前倒1分钟

            this.updateTimer = setInterval(() => {
                //
            }, 2000)
            this.isStart = true
        },
        stopRealtimeRefresh() {
            clearInterval(this.timer)
            clearInterval(this.updateTimer)
            this.isStart = false
        },
        async getChartData() {
            try {
                const today = new Date()
                //获取前一天的日期字符串
                const oneDayMs = 24 * 60 * 60 * 1000;
                let dateStr = ''
                if (today.getHours() >= 12) {
                    dateStr = DateTool.dateToStr(today, 'YYYY-MM-DD')
                } else {
                    const previousDay = new Date(today.getTime() - oneDayMs);
                    dateStr = DateTool.dateToStr(previousDay, 'YYYY-MM-DD')
                }
                this.period = await this.$api.getTitrationPeriod({
                    "deviceId": this.obj.sn,
                    "selectDate": dateStr,
                })
                const startTime = this.getStartTime()
                this.chartData = await this.$api.getTitrationChartData({
                    "deviceId": this.obj.sn,
                    "selectDate": dateStr,
                    "startTime": startTime,
                    "endTime": this.period.endTime
                })
                this.refreshChartTimeInterval()
            } catch (error) {
                this.$toast.showRed(error)
            }
        },
        async getChartDataOnRealtime() {
            try {
                const today = new Date()
                //获取前一天的日期字符串
                const oneDayMs = 24 * 60 * 60 * 1000;
                let dateStr = ''
                if (today.getHours() >= 12) {
                    dateStr = DateTool.dateToStr(today, 'YYYY-MM-DD')
                } else {
                    const previousDay = new Date(today.getTime() - oneDayMs);
                    dateStr = DateTool.dateToStr(previousDay, 'YYYY-MM-DD')
                }
                const data = await this.$api.getTitrationChartData({
                    "deviceId": this.obj.sn,
                    "selectDate": dateStr,
                    "startTime": this.lastRealTime,
                    "endTime": this.lastRealTime + this.realTimeInterval
                })
                this.lastRealTime += this.realTimeInterval
                this.chartData.pressure.push(...(data.pressure ?? []))
                this.chartData.event.CSA.push(...(data.event.CSA ?? []))
                this.chartData.event.Hypopnea.push(...(data.event.Hypopnea ?? []))
                this.chartData.event.OSA.push(...(data.event.OSA ?? []))
                this.refreshChartTimeRealtime()
            } catch (error) {
                this.$toast.showRed(error)
            }
        },

        refreshChart() {
            if (this.$refs.chartRef) {
                this.$refs.chartRef.refresh()
            }
        },
        refreshChartTimeInterval() {
            if (this.$refs.chartRef) {
                this.$refs.chartRef.refreshChartByZoom(this.currentTimeInterval)
            }
        },
        refreshChartTimeRealtime() {
            if (this.$refs.chartRef) {
                this.$refs.chartRef.refreshChartByRealtime()
            }
        },
        onClickTimeItem(id) {
            if (this.isStart) {
                return
            }
            this.currentTimeInterval = id
            // this.getChartData()
            if (this.$refs.chartRef) {
                this.$refs.chartRef.changeChartDataZoom(id)
            }
        },
        getStartTime() {
            return this.period?.startTime
            // let currentTimestamp = this.period?.endTime ?? Date.now();
            // let zoomMs = 0;

            // if (this.currentTimeInterval === 1) {
            //     zoomMs = 30 * 1000;
            // } else if (this.currentTimeInterval === 2) {
            //     zoomMs = 60 * 1000;
            // } else if (this.currentTimeInterval === 3) {
            //     zoomMs = 2 * 60 * 1000;
            // } else if (this.currentTimeInterval === 4) {
            //     zoomMs = 4 * 60 * 1000;
            // } else if (this.currentTimeInterval === 5) {
            //     zoomMs = 8 * 60 * 1000;
            // } else if (this.currentTimeInterval === 6) {
            //     zoomMs = 30 * 60 * 1000;
            // } else if (this.currentTimeInterval === 7) {
            //     return this.period?.startTime
            // } else if (this.currentTimeInterval === 8) {
            //     zoomMs = 10 * 1000;
            // } else if (this.currentTimeInterval === 9) {
            //     zoomMs = 15 * 1000;
            // } else if (this.currentTimeInterval === 10) {
            //     zoomMs = 20 * 1000;
            // }
            // return currentTimestamp - zoomMs
        },
    },
}
</script>
<style scoped lang='scss'>
.item-container {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #F1F1F1;
    overflow: hidden;

    .header {
        width: 100%;
        height: 30px;
        background-color: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            margin-left: 16px;
            display: flex;
            align-items: center;

            .name {
                height: 22px;
                font-size: 16px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 22px;
            }

            .device-circle {
                margin-left: 15px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #C2C2C2;
            }

            .online {
                background-color: #00C103;
            }

            .device {
                margin-left: 8px;
                height: 17px;
                font-size: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                color: #757575;
                line-height: 17px;
            }
        }

        .right {
            margin-right: 16px;
            display: flex;
            align-items: center;

            .operate {
                width: 20px;
                height: 20px;

                .icon {
                    width: 100%;
                    height: 100%;
                }
            }

            .m-r-22 {
                margin-right: 22px;
            }
        }
    }

    .content {
        width: 100%;
        height: calc(100% - 30px);

        .empty {
            position: relative;
            top: calc(50% - 12.5px);
            width: 100%;
            height: 100%;
            font-size: 25px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #000000;
            text-align: center;
        }
    }

    .time-list {
        display: flex;
        align-items: center;

        .time-list-item {
            width: 30px;
            height: 20px;
            background-color: #FFFFFF;
            border-radius: 3px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            font-size: 12px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.25);
            line-height: 20px;
            text-align: center;
            cursor: pointer;

            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }

        .time-list-item-select {
            color: #2779FF;
            background-color: #ECF2FE;
            border: 1px solid #ECF2FE;
        }
    }
}

.checkbox-group {
    display: flex;
    flex-direction: column;

    /deep/.el-checkbox {
        height: 30px;
        display: flex;
        align-items: center;
        margin-right: 0px;
        width: 98px;

        .el-checkbox__label {
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
        }
    }

}
</style>

<style>
.titration-popper {
    min-width: 98px !important;
    padding: 6px 14px !important;
    border-radius: 6px !important;
}
</style>