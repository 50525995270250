<template>
  <div class="list-container">
    <div class="list-container-input">
      <ElInput placeholder="姓名或手机号" v-model="keyword" suffix-icon="el-icon-search" @change="onSearch"></ElInput>
    </div>
    <div class="list">
      <RemoteTitrationPatientListCell v-for="item in dataArray" :key="item.id" :obj="item" :watch="isWatchCell(item)"
        :online="item.onlineStatus === 1" @onClickCell="onClickCell(item)"></RemoteTitrationPatientListCell>
    </div>
    <RemoteTitrationPatientListPagination :current="currentPage" :total="totalPage" :page-size="10"
      @currentChange="onPageChange"></RemoteTitrationPatientListPagination>
  </div>
</template>

<script>
import { Input } from 'element-ui'
import RemoteTitrationPatientListCell from './remote-titration-patient-list-cell.vue';
import RemoteTitrationPatientListPagination from './remote-titration-patient-list-pagination.vue';
export default {
  components: {
    ElInput: Input,
    RemoteTitrationPatientListCell,
    RemoteTitrationPatientListPagination
  },
  props: {
    watchPatientArray: Array
  },
  data() {
    return {
      //搜索
      keyword: "",
      //列表
      dataArray: [],
      total: 0,
      totalPage: 0,
      currentPage: 1
    }
  },

  computed: {
    organizationModel() {
      return this.$store.state.organizationModel;
    }
  },

  watch: {
    organizationModel(newVal, oldVal) {
      if (newVal?.id != oldVal?.id) {
        this.keyword = ''
        this.currentPage = 1
        this.getPatientList();
      }
    }
  },
  created() {
    this.getPatientList();
  },
  mounted() {

  },
  methods: {
    onSearch() {
      this.currentPage = 1;
      this.getPatientList()
    },

    async getPatientList() {
      const params = {
        hospitalId: this.organizationModel?.id ?? null,
        keyword: this.keyword,
        pageNum: this.currentPage,
        pageSize: 10,
      };
      try {
        const data = await this.$api.getTitrationPatientList(params);

        this.dataArray = data.list;
        this.total = data.total;
        this.totalPage = data.pages;
      } catch (error) {
        this.$toast.showRed(error);
      }

    },

    onPageChange(value) {
      this.currentPage = value;
      this.getPatientList()
    },

    isWatchCell(item) {
      const index = this.watchPatientArray.findIndex(el => el.id === item.id)
      return index !== -1
    },

    onClickCell(item) {
      if (this.watchPatientArray.length >= 4) {
        this.$toast.showRed('最多只能同时选择4位患者')
        return
      }
      const index = this.watchPatientArray.findIndex(el => el.id === item.id)
      if (index === -1) {
        const array = [...this.watchPatientArray]
        array.push(item)
        this.$emit('update:watchPatientArray', [...array])
      }
    }
  },
}
</script>
<style scoped lang='scss'>
.list-container {
  padding: 15px 0px;
  background-color: white;
  box-sizing: border-box;

  &-input {
    padding: 0 6px;
  }

  .list {
    height: calc(100% - 100px);
  }
}
</style>