<template>
    <div class="pagination">
        <button class="arrow" :disabled="pageNum <= 1" @click.stop.prevent="onChange(pageNum-1)"><i class="el-icon-arrow-left"></i></button>
        <ElInput v-model="pageNum" type="number" :max="total" min="1" step="1" @change="onChange"></ElInput>
        <span class="segment">/</span>
        <span class="total">{{total}}</span>
        <button class="arrow" :disabled="pageNum >= total" @click.stop.prevent="onChange(pageNum+1)"><i class="el-icon-arrow-right"></i></button>
    </div>
</template>

<script>
import { Input } from "element-ui";
export default {
    components: {
        ElInput: Input
    },
    props: {
        current: Number,
        total: Number,
        pageSize: {
            type: Number,
            default: 10
        },
    },
    data() {
        return {
            pageNum: 1
        }
    },
    created() {

    },
    mounted() {

    },
    watch: {
        current: {
            immediate: true,
            handler(newVal) {
                this.pageNum = newVal
            }
        }
    },
    methods: {
        // current-change
        onChange(value) {
            value = Math.ceil(value)
            if (value > this.total || value < 1) {
                this.pageNum = this.current
                return
            }
            this.$emit('currentChange', value)
        },
    },
}
</script>
<style scoped lang='scss'>
.pagination {
    margin-top: 10px;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;

    .arrow {
        width: 20px;
        height: 20px;
    }

    .segment {
        width: 8px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        line-height: 20px;
    }

    .total {
        width: 8px;
        height: 20px;
        font-size: 12px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.88);
        line-height: 20px;
    }

    /deep/.el-input {
        width: 40px;
        height: 20px;
    }

    /deep/.el-input input::-webkit-outer-spin-button,
    /deep/.el-input input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    /deep/.el-input input[type="number"] {
        -moz-appearance: textfield;
    }

    /deep/.el-input__inner {
        width: 40px;
        height: 20px;
        padding: 0;
        text-align: center;
    }
}
</style>