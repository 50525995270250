<template>
  <div class="remote-titration-main" ref="titrationMainRef">
    <template v-if="watchPatientArray.length">
      <div class="container" :style="{'grid-template-columns': gridColumns, 'grid-template-rows': gridRows}">
        <RemoteTitrationMainItem ref="chartItems" v-for="item in watchPatientArray" :key="item.id" :obj="item" :small="watchPatientArray.length > 2" @onClickWatch="$emit('onClickWatch', item)"></RemoteTitrationMainItem>
      </div>
    </template>
    <template v-else>
      <div class="empty">
        请选择患者
      </div>
    </template>

  </div>
</template>

<script>
import RemoteTitrationMainItem from './remote-titration-main-item.vue'
export default {
  components: {
    RemoteTitrationMainItem
  },
  props: {
    watchPatientArray: Array
  },
  data() {
    return {
      titrationData: [],
      containerHeight: 0,
    }
  },
  computed: {
    gridColumns() {
      return this.watchPatientArray.length === 1 ? '1fr' : '1fr 1fr'
    },
    gridRows() {
      return this.watchPatientArray.length <= 2 ? `${this.containerHeight}px` : `${this.containerHeight / 2}px ${this.containerHeight / 2}px`
    }
  },
  watch: {
    watchPatientArray: {
      immediate: true,
      handler(newVal) {
        if (this.$refs.chartItems) {
          console.log()
          this.$refs.chartItems.forEach(el => {
            el.refreshChart()
          })
        }
      }
    }
  },
  created() {

  },
  mounted() {
    this.containerHeight = this.$refs.titrationMainRef.offsetHeight
  },
  methods: {

  },
}
</script>
<style scoped lang='scss'>
.remote-titration-main {
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
}

.empty {
  width: 100%;
  height: 100%;
  margin-top: 15%;
  display: flex;
  justify-content: center;
  height: 36px;
  font-size: 25px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 36px;
}
</style>