import { render, staticRenderFns } from "./remote-titration-patient-list-pagination.vue?vue&type=template&id=112ed076&scoped=true"
import script from "./remote-titration-patient-list-pagination.vue?vue&type=script&lang=js"
export * from "./remote-titration-patient-list-pagination.vue?vue&type=script&lang=js"
import style0 from "./remote-titration-patient-list-pagination.vue?vue&type=style&index=0&id=112ed076&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112ed076",
  null
  
)

export default component.exports